import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';
import axios from 'axios';
import {useEffect, useState} from 'react';
import { Navigate  } from 'react-router-dom';


const About = lazy(() => import('./pages/AboutMe'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const Projects = lazy(() => import('./pages/Projects'));
const ProjectSingle = lazy(() => import('./pages/ProjectSingle.jsx'));


function App() {
	
	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark">
				<Router>
					<ScrollToTop />
					<AppHeader />
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home />} />
							
							<Route path="projects" element={<Projects />} />
							<Route
								path="projects/single-project"
								element={<ProjectSingle />}
							/>
							
							<Route path='/exploration-craft' element={ <Navigate to="/projects/single-project?id=1" /> }/>
							<Route path='/spaceflight-factory' element={ <Navigate to="/projects/single-project?id=2" /> }/>
							<Route path='/boat-craft' element={ <Navigate to="/projects/single-project?id=3" /> }/>
							<Route path='/graphic-study-spaceflight-factory' element={ <Navigate to="/projects/single-project?id=4" /> }/>
							<Route path='/raytracer-povray-parser' element={ <Navigate to="/projects/single-project?id=5" /> }/>
							<Route path='/tower-bruiser' element={ <Navigate to="/projects/single-project?id=6" /> }/>

							<Route path="about" element={<About />} />
							<Route path="contact" element={<Contact />} />
							<Route path="contact/" element={<Contact />} />
							
							<Route path="*" element={<Projects />} />
							 
						</Routes>
					</Suspense>
					<AppFooter />
				</Router>
				<UseScrollToTop />
			</div>
		</AnimatePresence>
	);
}

export default App;
